export const SOURCE_TYPES = {
  NEWS: 'News',
  AGENCY: 'Agency'
};

export enum NEWS_SOURCE_ATTRIBUTES {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  IS_SHARED = 'isShared',
  LOGO_URL = 'logoUrl',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  // related attributes (from related tables)
  JURISDICTIONS = 'jurisdictions',
  JURISDICTION_IDS = 'jurisdictionIds',
  AGENCY = 'agency'
}

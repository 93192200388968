import type { NewsSourcesTableConfig } from './useNewsSourcesTableConfig.types';

import * as uiLib from '@compliance.ai/web-components';
import * as sourcesConstants from 'admin/constants/sources';
import * as newsSourcesConstants from 'shared/features/sources/newsSources/newsSources.constants';
import * as elements from '../elements';
import * as documentConstants from '../../../../constants/document';

export const useNewsSourcesTableConfig = (): NewsSourcesTableConfig => {
  const columns: uiLib.Column[] = [
    {
      title: 'Name',
      dataKey: sourcesConstants.NEWS_SOURCE_ATTRIBUTES.NAME,
      sortKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.NAME,
      isDynamic: false,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'ID',
      dataKey: sourcesConstants.NEWS_SOURCE_ATTRIBUTES.ID,
      sortKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.ID,
      isDynamic: false,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.XS
    },
    {
      title: 'Jurisdiction',
      dataKey: sourcesConstants.NEWS_SOURCE_ATTRIBUTES.JURISDICTIONS,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: elements.JurisdictionCell,
      width: uiLib.TABLE_COLUMN_WIDTH.M
    },
    {
      title: 'Status',
      dataKey: sourcesConstants.NEWS_SOURCE_ATTRIBUTES.IS_SHARED,
      sortKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.IS_SHARED,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Logo URL',
      dataKey: sourcesConstants.NEWS_SOURCE_ATTRIBUTES.LOGO_URL,
      sortKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.LOGO_URL,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.LinkCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Created at',
      dataKey: sourcesConstants.NEWS_SOURCE_ATTRIBUTES.CREATED_AT,
      sortKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.CREATED_AT,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    },
    {
      title: 'Updated at',
      dataKey: sourcesConstants.NEWS_SOURCE_ATTRIBUTES.UPDATED_AT,
      sortKey: newsSourcesConstants.NEWS_SOURCES_DATA_FETCH_QUERY_PARAMS.UPDATED_AT,
      isDynamic: true,
      isInitiallyVisible: true,
      Component: uiLib.TextCell,
      width: uiLib.TABLE_COLUMN_WIDTH.S
    }
  ];

  return {
    columns
  };
};

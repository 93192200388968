import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Typography from '../../../components/Typography';
import Button from "../../../components/Button";
import TextInput from "../../../components/TextInput";
import Select from "../../../components/ReactSelect/Select";
import {
  AGENCY_GENERAL,
  AGENCY_WORDS,
  AGENCY_TYPES
} from './AgencyDetails.constants';
import AsyncSelect from "../../../components/ReactSelect/SelectAsync";
import { loadJurisdictionsOptions, calculateSourceTypes } from "./AgencyDetails.helper";
import Switch from "../../../components/Switch";
import ExpandableList from "../../../components/ExpandableList";

const AgencyDetailsGeneral = ({
  editMode,
  toggleEditMode,
  agencyDetails,
  saveChanges,
  handleChanges,
  handleChangesEvent,
  errorFields,
  switchSource,
  isNewMode
}) => {
  const disableEdit = !editMode;

  const AGENCY_TYPES_OPTIONS = useMemo(
    () => Object.values(AGENCY_TYPES).map(item => ({ label: item, value: item })),
    []
  );

  const SOURCE_TYPES_OPTIONS = calculateSourceTypes();

  return (
    <div className="general-info">
      <div className="general-info_header">
        <Typography variant="h5">General info</Typography>
        <div className="general-info_header_btn-block">
          <Button
            size="custom"
            color="highlighted"
            onClick={toggleEditMode}
          >
            {editMode ? 'Cancel' : 'Edit'}
          </Button>
          {editMode && (
            <Button
              size="custom"
              color="success"
              onClick={saveChanges}
            >
              Save Changes
            </Button>
          )}
        </div>
      </div>
      <div className="general-info_body">
        {/* First row */}
        <TextInput
          className="input_4"
          label="Name"
          name={AGENCY_GENERAL.name}
          value={agencyDetails[AGENCY_GENERAL.name]}
          isDisabled={disableEdit}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_GENERAL.name)}
        />
        <TextInput
          className="input_2"
          label="ID"
          isDisabled={disableEdit || !isNewMode}
          name={AGENCY_GENERAL.agencyId}
          value={agencyDetails[AGENCY_GENERAL.agencyId]}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_GENERAL.agencyId)}
        />
        <Select
          className="input_2"
          label="Source Type"
          isDisabled={disableEdit}
          value={agencyDetails[AGENCY_GENERAL.sourceType]}
          options={SOURCE_TYPES_OPTIONS}
          onChange={switchSource}
        />
        {/* Second row */}
        <TextInput
          className="input_8"
          label="Description"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.description}
          value={agencyDetails[AGENCY_GENERAL.description]}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_GENERAL.description)}
        />
        {/* Third row */}
        <TextInput
          className="input_2"
          label="Short Name"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.shortName}
          value={agencyDetails[AGENCY_GENERAL.shortName]}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_GENERAL.shortName)}
        />
        <AsyncSelect
          className="input_2"
          label="Jurisdiction"
          isDisabled={disableEdit}
          value={agencyDetails[AGENCY_GENERAL.jurisdiction]}
          onChange={(e) => handleChanges(AGENCY_GENERAL.jurisdiction, e)}
          loadOptions={
            value => loadJurisdictionsOptions('short_name', value, 'short_name')
          }
          isClearable
        />
        <Select
          className="input_2"
          label="Agency Type"
          isDisabled={disableEdit}
          value={agencyDetails[AGENCY_GENERAL.type]}
          options={AGENCY_TYPES_OPTIONS}
          onChange={(e) => handleChanges(AGENCY_GENERAL.type, e)}
        />
        <TextInput
          className="input_2"
          label="Parend ID"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.parentID}
          value={agencyDetails[AGENCY_GENERAL.parentID]}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_GENERAL.parentID)}
        />
        {/* Fourth row */}
        <TextInput
          className="input_4"
          label="URL"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.url}
          value={agencyDetails[AGENCY_GENERAL.url]}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_GENERAL.url)}
        />
        <TextInput
          className="input_4"
          label="Slug"
          isDisabled={disableEdit}
          name={AGENCY_GENERAL.slug}
          value={agencyDetails[AGENCY_GENERAL.slug]}
          onChange={handleChangesEvent}
          isError={errorFields.includes(AGENCY_GENERAL.slug)}
        />
        {/* Fourth row */}
        <Switch
          name={AGENCY_GENERAL.popular}
          label="Popular"
          className="input_2"
          value={agencyDetails[AGENCY_GENERAL.popular]}
          onChange={handleChangesEvent}
          isDisabled={disableEdit}
        />
        <Switch
          name={AGENCY_GENERAL.blacklisted}
          label="Blacklisted"
          className="input_2"
          value={agencyDetails[AGENCY_GENERAL.blacklisted]}
          onChange={handleChangesEvent}
          isDisabled={disableEdit}
        />
        <div className="input_4" />
        {/* Fifth row */}
        <ExpandableList
          label="Synonyms"
          className="input_1"
          name={AGENCY_WORDS.synonyms}
          value={agencyDetails[AGENCY_WORDS.synonyms]}
          isDisabled={disableEdit}
          onChange={handleChanges}
        />
        <ExpandableList
          label="Departments"
          className="input_1"
          name={AGENCY_WORDS.departments}
          value={agencyDetails[AGENCY_WORDS.departments]}
          isDisabled={disableEdit}
          onChange={handleChanges}
        />
        <ExpandableList
          label="Sub-departments"
          className="input_1"
          name={AGENCY_WORDS.subdepartments}
          value={agencyDetails[AGENCY_WORDS.subdepartments]}
          isDisabled={disableEdit}
          onChange={handleChanges}
        />
      </div>
    </div>

  );
};

AgencyDetailsGeneral.propTypes = {
  editMode: PropTypes.bool,
  toggleEditMode: PropTypes.func.isRequired,
  agencyDetails: PropTypes.shape({}),
  handleChanges: PropTypes.func.isRequired,
  handleChangesEvent: PropTypes.func.isRequired,
  switchSource: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string),
  isNewMode: PropTypes.bool.isRequired
};

AgencyDetailsGeneral.defaultProps = {
  editMode: false,
  agencyDetails: {},
  errorFields: []
};

export default AgencyDetailsGeneral;
